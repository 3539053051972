<ng-container *ngIf="input.multiple | parse: context | async">
  <!-- Native dropdown with multiple select -->
  <ng-container *ngIf="(isLarge$ | async) != true">
    <mat-form-field>
      <select
        matNativeControl
        [formControl]="_formControl"
        [placeholder]="input.placeholder | parse: context | async"
        [required]="input.required"
        (blur)="onBlur($event)"
        multiple
      >
        <option
          *ngFor="let option of parsedOptions$ | async"
          (click)="removePreviousValue()"
          [ngValue]="option[input.optionsValueKey || 'value']"
          [disabled]="option.disabled"
        >
          <ng-container
            *ngIf="
              input?.optionWidget as optionWidget;
              else defaultOptionTemplate
            "
          >
            <ng-container *ngComponentOutlet="optionWidget.type; inputs" />
            <ng-container
              [dynamicWidget]="optionWidget"
              [context]="option"
            ></ng-container>
          </ng-container>
          <ng-template #defaultOptionTemplate>
            {{
              option[input.optionsLabelKey || 'label'] | parse: context | async
            }}
          </ng-template>
        </option>
      </select>
      <mat-error *ngIf="firstFormControlError as error">
        {{ error.errorMessage }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <!-- Material dropdown with  multiple select  -->
  <ng-container *ngIf="isLarge$ | async">
    <mat-form-field>
      <mat-label>{{ input.label | parse: context | async }}</mat-label>
      <mat-select
        (selectionChange)="removePreviousValue()"
        [formControl]="_formControl"
        [placeholder]="input.placeholder | parse: context | async"
        [required]="input.required"
        (blur)="onBlur($event)"
        multiple
      >
        <div mat-option class="filter-option mat-option">
          <mat-checkbox
            #allSelected
            (click)="toggleAllSelection()"
            [formControl]="checked"
            color="primary"
            >All
          </mat-checkbox>
        </div>
        <mat-option
          *ngFor="let option of parsedOptions$ | async"
          [value]="option[input.optionsValueKey || 'value']"
          [disabled]="option.disabled"
          (click)="onClickOption()"
        >
          <ng-container
            *ngIf="
              input?.optionWidget as optionWidget;
              else defaultOptionTemplate
            "
          >
            <ng-container
              [dynamicWidget]="optionWidget"
              [context]="option"
            ></ng-container>
          </ng-container>
          <ng-template #defaultOptionTemplate>
            {{
              option[input.optionsLabelKey || 'label'] | parse: context | async
            }}
          </ng-template>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="firstFormControlError as error">
        {{ error.errorMessage }}
      </mat-error>
    </mat-form-field>
  </ng-container>
</ng-container>

<ng-container *ngIf="(input.multiple | parse: context | async) != true">
  <!-- Native select dropdown -->
  <ng-container *ngIf="(isLarge$ | async) != true">
    <mat-form-field>
      <mat-label>{{ input.label | parse: context | async }}</mat-label>
      <select
        matNativeControl
        [formControl]="_formControl"
        [placeholder]="input.placeholder | parse: context | async"
        [required]="input.required"
        (blur)="onBlur($event)"
        (change)="updateLabelByValue($event?.target?.value)"
      >
        <option
          *ngFor="let option of parsedOptions$ | async"
          [ngValue]="option[input.optionsValueKey || 'value']"
          [disabled]="option.disabled"
        >
          <ng-container
            *ngIf="
              input?.optionWidget as optionWidget;
              else defaultOptionTemplate
            "
          >
            <ng-container
              [dynamicWidget]="optionWidget"
              [context]="option"
            ></ng-container>
          </ng-container>
          <ng-template #defaultOptionTemplate>
            {{
              option[input.optionsLabelKey || 'label'] | parse: context | async
            }}
          </ng-template>
        </option>
      </select>
      <mat-error *ngIf="firstFormControlError as error">
        {{ error.errorMessage }}
      </mat-error>
    </mat-form-field>
  </ng-container>
  <!-- material select dropdown -->
  <ng-container *ngIf="isLarge$ | async">
    <mat-form-field>
      <mat-label>{{ input.label | parse: context | async }}</mat-label>
      <mat-select
        [formControl]="_formControl"
        [placeholder]="input.placeholder | parse: context | async"
        [required]="input.required"
        (blur)="onBlur($event)"
      >
        <mat-option
          *ngFor="let option of parsedOptions$ | async"
          (click)="updateLabelByValue(option[input.optionsValueKey || 'value'])"
          [value]="option[input.optionsValueKey || 'value']"
          [disabled]="option.disabled"
        >
          <ng-container
            *ngIf="
              input?.optionWidget as optionWidget;
              else defaultOptionTemplate
            "
          >
            <ng-container
              [dynamicWidget]="optionWidget"
              [context]="option"
            ></ng-container>
          </ng-container>
          <ng-template #defaultOptionTemplate>
            {{
              option[input.optionsLabelKey || 'label'] | parse: context | async
            }}
          </ng-template>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="firstFormControlError as error">
        {{ error.errorMessage }}
      </mat-error>
    </mat-form-field>
  </ng-container>
</ng-container>
