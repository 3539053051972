import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterOutlet } from '@angular/router';
import { Select } from '@ngxs/store';
import { NgCommonModule, registerAsync } from '@trackback/ng-common';
import { NgLangModule, TranslationState } from '@trackback/ng-lang';
import {
  ConditionalWidget,
  DataStoreWidget,
  DataWidget,
  ForeachWidget,
  SwitchWidget,
} from '@trackback/ng-widgets';
import { Observable } from 'rxjs';
import { DEFAULT_CLIENT_ERROR_WIDGET } from './models/default-widgets';
import './widgets/chips-field/chips-field.component';
import './widgets/expansion-panel-table/expansion-panel-table.component';
import './widgets/insert-remove-animate.component';
import './widgets/progress-bar.component';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import packageJSON from '../../package.json';

registerAsync('chart', () => import('./widgets/chart.component'));
registerAsync('Editor', () => import('./widgets/editor.component'));
registerAsync('qrcode', () => import('./widgets/qr-code.component'));

@Component({
  selector: 'tb-root',
  template: `
    <ng-container *ngIf="languageInitialized$ | async; else fallback">
      <router-outlet></router-outlet>
    </ng-container>
    <ng-template #fallback>
      <ng-container *ngIf="translationError$ | async as error; else loading">
        <ng-container [dynamicWidget]="errorWidget"></ng-container>
      </ng-container>
      <ng-template #loading>
        <div
          style="display:flex; flex-direction: column; justify-content:center; align-items:center; height:100%;"
        >
          <div
            style="color: #28232A; font-size: 24pt; font-weight: 600; font-family: sans-serif"
          >
            TrackBack
          </div>
          <div
            style="position:relative; margin-top: 25px; height:18px; width:68px;"
          >
            <div
              style="position:absolute; top:0; left:0; width:18px; height:18px; background-color:#8C0000; border-radius:100%; display:inline-block; -webkit-animation:anim 1s infinite ease-in-out both; animation:anim 1s infinite ease-in-out both; -webkit-animation-delay:-0.32s; animation-delay:-0.32s;"
            ></div>
            <div
              style="position:absolute; top:0; left:25px; width:18px; height:18px; background-color:#8C0000; border-radius:100%; display:inline-block; -webkit-animation:anim 1s infinite ease-in-out both; animation:anim 1s infinite ease-in-out both; -webkit-animation-delay:-0.16s; animation-delay:-0.16s;"
            ></div>
            <div
              style="position:absolute; top:0; left:50px; width:18px; height:18px; background-color:#8C0000; border-radius:100%; display:inline-block; -webkit-animation:anim 1s infinite ease-in-out both; animation:anim 1s infinite ease-in-out both;"
            ></div>
          </div>
        </div>
      </ng-template>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NgCommonModule,
    NgLangModule,
    MatDateFnsModule,
    MatTooltipModule,
  ],
})
export class AppComponent {
  @Select(TranslationState.isInitialized)
  languageInitialized$: Observable<boolean>;

  @Select(TranslationState.getLoadingError)
  translationError$: Observable<Error>;

  readonly errorWidget = DEFAULT_CLIENT_ERROR_WIDGET;
  @HostBinding('attr.data-version')
  readonly APP_VERSION = packageJSON.version;

  /**
   * Necessary to exclude the classes of structural widgets from tree-shaking in production builds.
   * Once the components have been moved to the main application, the same effect can be achieved by declaring them as a static import
   * e.g. import "structural-widgets/foreach-widget.ts"
   */
  static readonly STATIC_IMPORTS = [
    ConditionalWidget,
    DataWidget,
    DataStoreWidget,
    ForeachWidget,
    SwitchWidget,
  ];
}
